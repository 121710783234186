import {Http} from "../../infrastructure/http"

const http = new Http()

export const handleCreatePayment = (subscriptionId) => {

    return new Promise((resolve, reject) => {

        http.post(
            "public/transactions/payment",
            {
                subscription_id: subscriptionId,
            },
            (err, res) => {

                if (err) {
                    reject(err);
                    return;
                }

                if (res.status > 299) {
                    reject(res.data.error);
                    return;
                }

                resolve({
                    statusCode: res.status,
                    data: res.data
                })
            }
        )
    });

}

import React, {useEffect, useState} from 'react';
import {useStaticQuery, graphql, Link} from 'gatsby';
import {Icon} from 'react-icons-kit';
import {androidArrowForward} from 'react-icons-kit/ionicons/androidArrowForward';
import Box from 'common/components/Box';
import Heading from 'common/components/Heading';
import Text from 'common/components/Text';
import Container from 'common/components/UI/Container';
import GatsbyImage from 'common/components/GatsbyImage';
import BannerArea, {Col} from './banner.style';
import {handleListCountries} from "../../../usecases/country/listCountries";
import SignUpModal from "../../../custom/components/home/SignUpModal";
import {useCookies} from "react-cookie";

const Banner = (props) => {

    const [countries, setCountries] = useState([]);
    const [defaultFlag, setDefaultFlag] = useState("/img/flags/us.svg");
    const [defaultPhoneCode, setDefaultPhoneCode] = useState("+1");
    const [selectedCountry, setSelectedCountry] = useState("US");
    const [toggleState, setToggleState] = useState(false);
    const [cookies, setCookie] = useCookies(['phone']);
    const [phoneSelected, setPhoneSelected] = useState(false);
    const [modalState, setModalState] = useState(false);

    const getCountries = () => {

        handleListCountries((error, countries) => {
            if (error) {
                return;
            }
            if (countries) {
                setCountries(countries)
            }
        })

    }

    const selectPhoneCode = (element, iso, code) => {
        setSelectedCountry(iso);
        iso = iso.toLowerCase();
        setDefaultFlag("/img/flags/" + iso + ".svg");
        setDefaultPhoneCode("+" + code);

    }

    const toggleFlags = () => {
        setToggleState(!toggleState);
    }

    const handleLocateButton = () => {
        window.history.pushState({path: 'create-account'}, 'Create Account', '/create-account');
        const d = new Date();
        d.setTime(d.getTime() + (60 * 60 * 1000));

        const phone = document.getElementById('phone');

        if (!phone.value || phone.value === "") {
            phone.style.border = '2px solid red';
            return;
        }

        phone.style.border = '0';
        setPhoneSelected(phone.value);
        setModalState(true);
        setCookie("phone", phone.value, {path: "/", expires: d});

        const body = document.body;
        body.style.overflowY = 'hidden';

    }

    useEffect(() => {
        getCountries();
        const phone = cookies && cookies.phone ? cookies.phone : false;
        if (phone) {
            const phoneInput = document.getElementById('phone');
            phoneInput.value = phone;
            setPhoneSelected(phone);
        }

    }, []);

    const Data = useStaticQuery(graphql`
    query {
      appMinimalJson {
        BannerData {
          title
          text
          button {
            link
            label
          }
          image {
            childImageSharp {
              gatsbyImageData(
                width: 945
                placeholder: BLURRED
                formats: [AUTO, WEBP, AVIF]
              )
            }
          }
          tagline
        }
      }
    }
  `);
    const {title, text, button, image, tagline} =
        Data.appMinimalJson.BannerData;

    return (
        <BannerArea id="banner_section">
            <SignUpModal
                phone={phoneSelected}
                prefix={defaultPhoneCode}
                modalState={modalState}
                setModalState={setModalState}
                product={props.product}
            />
            <GatsbyImage
                src={
                    (image !== null) | undefined
                        ? image.childImageSharp.gatsbyImageData
                        : {}
                }
                className="bannerMoc"
                alt="banner image"
            />
            <Container className="Container">
                <Col>
                    <Heading as="h2" content={title}/>
                    <Text as="p" content={text}/>
                    <Box className="ButtonWrap">
                        <div className="flag-container">
                            <div className="dial-select" onClick={() => {
                                toggleFlags()
                            }}>
                                <a className="toggle">
                                    <img src={defaultFlag} width="20px" alt="US"/>
                                    <span>{defaultPhoneCode}</span>
                                </a>
                                <div className={toggleState ? 'visible FlagMenu' : 'FlagMenu'}>
                                    {countries && countries.map((item, i) => {
                                        return <a key={i} onClick={(e) => {
                                            selectPhoneCode(e, item.iso, item.phone_code)
                                        }}>
                                            <img src={`/img/flags/${item.iso.toLowerCase()}.svg`} alt={item.iso}
                                                 width="20px"/>
                                            <span>+{item.phone_code}</span>
                                            <span>{item.name}</span>
                                        </a>
                                    })}
                                </div>
                            </div>
                            <input className="Input" id="phone" autoFocus="autoFocus" tabIndex="1" type="phone"
                                   placeholder="Mobile phone to localize"/>
                            <input type="hidden" id="country" value={selectedCountry}/>
                            <button type='button' className="Button" style={{border: '0', cursor:'pointer', borderRadius: '0px'}} onClick={() => {
                                handleLocateButton();

                            }}>{button.label} <Icon size={18} icon={androidArrowForward}/></button>
                        </div>
                    </Box>
                </Col>
            </Container>
        </BannerArea>
    );
};

export default Banner;

import styled from 'styled-components';

const SignupModalArea = styled.div`
  top: 0;
  left: 0;
  position: fixed;
  z-index: 9999;
  width: 100%;
  height: 100vh;
  background: rgba(255, 255, 255, 0.75);
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  right: 0;
  bottom: 0;


  .close {
    position: absolute;
    top: 8px;
    right: 8px;
    color: #000;
    cursor: pointer;
    -webkit-transition: color .15s ease-out;
    transition: color .15s ease-out;
    z-index: 2;
  }

  .icon {
    display: inline-flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    width: 18px;
    height: 18px;
  }

  .icon svg {
    fill: currentColor;
    width: 100%;
    height: 100%;
  }

  .modal-container {
    max-width: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    position: relative;
    width: 100%;
    max-height: 90vh;
    background-color: #fff;
    border-radius: .75rem;


    @media (min-width: 768px) {
      max-width: 480px;
      -webkit-box-shadow: 0 10px 150px rgb(0 0 0 / 25%);
      box-shadow: 0 10px 150px rgb(0 0 0 / 25%);

    }

    @media (max-width: 700px) {
      height: 100%;
      max-height: 100%;

    }
  }

  .scroller {
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    position: relative;
    padding-right: 7%;
    overflow: hidden;
  }

  .scroller, .scroller > .inner {
    -webkit-box-flex: 1;
    -ms-flex: 1;
    flex: 1;
  }

  .scroller > .inner {
    -webkit-overflow-scrolling: touch;
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
    width: 110%;
    overflow-y: auto;
  }

  .n-register {
    position: relative;
    padding: 3rem;
  }

  .n-register .track-num {
    position: absolute;
    top: 3rem;
    right: 0;
    display: inline-block;
    padding: .75rem 3rem .75rem 1.5rem;
    border-top-left-radius: .75rem;
    border-bottom-left-radius: .75rem;
    margin-bottom: 1.5rem;
    min-width: 200px;
  }

  .n-register .track-num span:first-child {
    font-size: 60%;
  }

  .n-register .track-num span {
    display: block;
  }

  .n-register .track-num span:nth-child(2) {
    font-weight: 500;
  }

  .n-register h6 {
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    height: 45px;
  }

  .input-block {
    position: relative;
  }

  label {
    display: block;
    text-transform: uppercase;
    font-size: .85rem;
    line-height: 1.3;
    letter-spacing: 1px;
    margin-bottom: 10px;
  }

  small {
    font-size: 80%;
  }

  .input:not(.checkbox), .select {
    -webkit-appearance: none;
    background-color: initial;
    display: block;
    padding: .6rem 1rem;
    border: 1px solid #d1d1d7;
    color: #000;
    width: 100%;
    font-weight: 400;
    border-radius: .25rem;
    font-size: 15px;
    margin-bottom: 30px;
  }

  .checkbox-block label {
    margin-left: .75rem;
    display: inline-block;
  }
  
  .checkbox {
    position: relative;
    display: inline-block;
    width: 18px;
    -webkit-box-flex: 0;
    -ms-flex: 0 0 18px;
    flex: 0 0 18px;
    height: 18px;
    overflow: hidden;
    margin-top: 0!important;
    margin-bottom: 30px;
    vertical-align: text-top;
  }
  
  h3{
    font-size: 30px;
    padding-top: 10px;
  }
`;

export default SignupModalArea;

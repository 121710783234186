import React, {useEffect, useState} from 'react';
import SignupModalArea from "./signup.modal.style";
import {handleFindCustomerNonActive} from "../../../../usecases/customer/findCustomerNonActive";
import {handleCreateCustomer} from "../../../../usecases/customer/createCustomer";
import {handleUpdateCustomerPassword} from "../../../../usecases/customer/updateCustomerPassword";
import {handleFindCreatedByCustomer} from "../../../../usecases/subscription/findCreatedByCustomer";
import {handleCreateSubscription} from "../../../../usecases/subscription/createSubscription";
import {logger} from "../../../../infrastructure/logger";
import {handleCreatePayment} from "../../../../usecases/transaction/createPayment";
import {useCookies} from "react-cookie";
import {handleCreateMoneiSubscription} from "../../../../usecases/transaction/createMoneiSubscription";

const SignUpModal = props => {

    const [email, setEmail] = useState("");
    const [confirmEmail, setConfirmEmail] = useState("");
    const [password, setPassword] = useState("");
    const [fullName, setFullName] = useState("");
    const [isTermsOfServicesChecked, setIsTermsOfServicesChecked] = useState(
        false
    )

    const [validationError, setValidationError] = useState(false);
    const [nameError, setNameError] = useState(false);
    const [passwordError, setPasswordError] = useState(false);
    const [buttonText, setButtonText] = useState("CONTINUE");

    const [currentStep, setCurrenStep] = useState(1);
    const [loading, setLoading] = useState(false);
    const [customerId, setCustomerId] = useState(undefined);
    const [subscriptionId, setSubscriptionId] = useState(undefined);

    const [cookie, setCookie] = useCookies([]);

    const [gclid, setGclid] = useState("");

    useEffect(() => {

        setGclid(cookie['customer.gclid']);

    }, []);

    const handleCloseModal = () => {
        window.history.pushState({path: 'home'}, 'Home', '/');
        props.setModalState(false);
        const body = document.body;
        body.style.overflowY = 'auto';
        setCurrenStep(1);
        setButtonText("CONTINUE")
        setValidationError(false)
    }

    const handleCreateOrUpdateCustomer = () => {

        if (loading) {
            return;
        }

        setValidationError(false);
        setPasswordError(false);
        setNameError(false);
        setLoading(true);

        if (!fullName) {
            setNameError(true);
            setValidationError("Name cannot be empty");
            setLoading(false);
            return;
        }

        if (email === '' || confirmEmail === '') {
            setValidationError("Email cannot be empty");
            setLoading(false);
            return;
        }

        if (email !== confirmEmail) {
            setValidationError("Emails do not match");
            setLoading(false);
            return;
        }

        if (!password) {
            setPasswordError(true);
            setValidationError("Password cannot be empty");
            setLoading(false);
            return;
        }

        if (!isTermsOfServicesChecked) {
            setValidationError("Please, accept terms of use");
            setLoading(false);
            return;
        }

        getCustomerNonActiveByEmail(email, password, fullName)

    }

    const getCustomerNonActiveByEmail = (email, password, fullName) => {

        handleFindCustomerNonActive(email, (error, response) => {
            if (error) {
                setValidationError("Sorry, something went wrong, try later");
                setLoading(false);
                return;
            }
            let customerId = response.id ? response.id : false;
            setCustomerId(customerId);
            if (!customerId) {
                handleCreateCustomer(email, password, fullName, (error, response) => {
                    if (error) {
                        setValidationError("Cannot create account with this email");
                        setLoading(false);
                        return;
                    }
                    customerId = response.id;
                    setCustomerId(customerId);
                    handlePayment(customerId);
                });

                return;
            }

            handleUpdateCustomerPassword(customerId, password, (error) => {
                if (error) {
                    setValidationError("Sorry, something went wrong, try later");
                    setLoading(false);
                    return;
                }
                handlePayment(customerId);
            })


        })

    }

    const validateEmail = email => {
        if (!email || email === '') {
            return true;
        }
        const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        return re.test(String(email).toLowerCase())
    }

    const handlePayment = async (customerId) => {
        setValidationError(false);
        await createOrRetrieveSubscription(customerId)

    }

    const createOrRetrieveSubscription = async (customerId) => {

        try {
            const result = await handleFindCreatedByCustomer(customerId);
            let susId;

            if (result.data.length) {
                setSubscriptionId(result.data[0].id);
                susId = result.data[0].id;
            } else {
                const subscriptionResponse = await handleCreateSubscription(props.product.id, customerId, gclid);
                setSubscriptionId(subscriptionResponse.data.id);
                susId = subscriptionResponse.data.id;
            }

            await createNewTransaction(susId);

        } catch (error) {
            setLoading(false);
            logger.error(error)
            setValidationError('Something was wrong, try later.');
        }


    }

    const createNewTransaction = async (subscriptionId) => {
        const response = await handleCreatePayment(
            subscriptionId,
        );
        const transactionId = response.data.id;

        try {
            const moneiResponse = await handleCreateMoneiSubscription(
                transactionId,
                subscriptionId,
                email,
                password,
                props.phone,
                props.prefix,
            );
            if (moneiResponse.statusCode > 204) {
                setLoading(false);
                setValidationError('Something was wrong, try later.');
            }
            window.location.href = moneiResponse.data.payment_url;
        } catch (error) {
            setLoading(false);
            setValidationError(error.response.data.error);
        }
    }


    return (
        <SignupModalArea id="signUpModal" className={props.modalState ? 'flex' : 'hide'}>

            <div className="modal-container">
                <a className="close" onClick={() => {
                    handleCloseModal();
                }}><i className="icon close">
                    <svg xmlns="http://www.w3.org/2000/svg" height="100%" width="100%" viewBox="0 0 20 20"
                         className="svg-fill">
                        <path
                            d="M11.61,10l7.6-7.59A1,1,0,1,0,17.8,1L10,8.8,2.2,1a1,1,0,0,0-1.7.7,1,1,0,0,0,.29.71L8.39,10,.79,17.59A1,1,0,1,0,2.2,19L10,11.2,17.8,19a1,1,0,0,0,1.7-.7,1,1,0,0,0-.29-.71Z"></path>
                    </svg>
                </i></a>
                <div className="scroller">
                    <div className="inner">
                        <div className="n-register">
                            <div className="track-num bg-primary">
                                <span>Localization</span> <span>{props.prefix} {props.phone}</span>
                            </div>
                            <div id="signUpStep" style={{display: currentStep === 1 ? 'block' : 'none'}}>
                                <h6>Create Account</h6>
                                {props.product ?
                                    <h3 className="text-primary" style={{marginTop: "20px"}}>
                                        {props.product.free_trial}$
                                        <small> {props.product.name}</small>
                                    </h3>
                                    : ""
                                }
                                <form id="signup-form" className="mt2" style={{marginTop: "30px"}}>
                                    <div className="input-block">
                                        <label htmlFor="name">
                                            <span>Username</span> &zwnj;
                                            <small>*</small>
                                        </label>
                                        <input id="name" name="name"
                                               placeholder="John Doe"
                                               autoFocus="autofocus"
                                               required="required"
                                               type="text"
                                               className="input"
                                               value={fullName}
                                               onChange={event => setFullName(event.target.value)}
                                               style={{
                                                   border: nameError ? "1px solid red" : "1px solid #d1d1d7"
                                               }}
                                        />
                                    </div>
                                    <div className="input-block">
                                        <label htmlFor="email">
                                            <span>Email Address</span> &zwnj;
                                            <small>*</small>
                                        </label>
                                        <input id="email" name="email"
                                               placeholder="hello@yourmail.com"
                                               autoFocus="autofocus"
                                               required="required"
                                               type="email"
                                               className="input"
                                               value={email}
                                               onChange={event => setEmail(event.target.value)}
                                               style={{
                                                   border: validateEmail(email) !== true ? "1px solid red" : "1px solid #d1d1d7"
                                               }}
                                        />
                                    </div>
                                    <div className="input-block">
                                        <label htmlFor="confirmEmail">
                                            <span>Confirm Email Address</span> &zwnj;
                                            <small>*</small>
                                        </label>
                                        <input id="confirmEmail"
                                               name="confirmEmail"
                                               placeholder="hello@yourmail.com"
                                               required="required"
                                               type="email"
                                               className="input"
                                               value={confirmEmail}
                                               onChange={event => setConfirmEmail(event.target.value)}
                                               style={{
                                                   border: validateEmail(confirmEmail) !== true ? "1px solid red" : "1px solid #d1d1d7"
                                               }}
                                        />
                                    </div>
                                    <div className="input-block">
                                        <label htmlFor="password">
                                            <span>Password</span> &zwnj;
                                            <small>*</small>
                                        </label>
                                        <input id="password"
                                               name="password"
                                               placeholder="*********"
                                               required="required"
                                               type="password"
                                               className="input"
                                               value={password}
                                               onChange={event => setPassword(event.target.value)}
                                               style={{
                                                   border: !passwordError ? "1px solid #d1d1d7" : "1px solid red"
                                               }}
                                        />
                                    </div>
                                    <div className="input-block mt-15 checkbox-block">

                                        <input id="agree-1"
                                               name="agree-1"
                                               required="required"
                                               type="checkbox"
                                               className="inputinput checkbox"
                                               onChange={event =>
                                                   setIsTermsOfServicesChecked(event.target.checked === true)
                                               }/>
                                        <label htmlFor="agree-1">
                                            <span><span className="text-dark">I accept terms of use</span></span> &zwnj;
                                            <small>*</small></label>
                                    </div>
                                    <div style={{marginBottom: "10px"}}>
                                        <img src="/img/credit-card-icons-png.png" width="250"/>
                                    </div>

                                    <button type="button" className="btn-primary"
                                        onClick={() => {
                                        handleCreateOrUpdateCustomer()
                                    }}> {loading ? "PROCESSING..." : buttonText}
                                    </button>

                                    <div className="alert alert-danger"
                                         style={{display: validationError ? 'block' : 'none'}}>
                                        {validationError}
                                    </div>
                                </form>
                            </div>
                        </div>

                    </div>

                </div>
            </div>

        </SignupModalArea>
    )

}

export default SignUpModal;
